<template>
    <page-title
        title="学校・イベント一覧"
        icon="bi-mortarboard-fill"
    >
        <router-link :to="{name: 'SchoolAdd'}" class="btn btn-outline-primary">
            <i class="bi bi-plus-lg"></i> 新規登録
        </router-link>
    </page-title>

    <section class="section">
        <div class="row align-items-end">
            <div class="col-6">
                <label>学校名 / イベント名</label>
                <input type="text" class="form-control">
            </div>
            <div class="col-3">
                <button class="btn btn-outline-primary"><i class="bi bi-search"></i> 検索</button>
            </div>
        </div>
    </section>

    <section class="section">
        <table class="table  table-striped mb-4">
            <thead>
                <tr class="table-primary">
                    <th class="col-6 text-center">略称</th>
                    <th class="col-6 text-center">学校・イベント担当</th>
                    <th class="col-6 text-center">所在地</th>
                    <th class="col-6"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">北部小</td>
                    <td class="text-center">片岡</td>
                    <td class="text-center">大和郡山市</td>
                    <td class="text-center">
                        <router-link :to="{name: 'SchoolEdit', params:{id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                        <router-link :to="{name: 'Event', params: {id: 1}}" class="btn btn-outline-primary">
                            <i class="bi bi-calendar-event"></i> 行事マスタ
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">南部小</td>
                    <td class="text-center">片岡</td>
                    <td class="text-center">大和郡山市</td>
                    <td class="text-center">
                        <router-link :to="{name: 'SchoolEdit', params:{id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                        <router-link :to="{name: 'Event', params: {id: 1}}" class="btn btn-outline-primary">
                            <i class="bi bi-calendar-event"></i> 行事マスタ
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">中央小</td>
                    <td class="text-center">奥村</td>
                    <td class="text-center">大和郡山市</td>
                    <td class="text-center">
                        <router-link :to="{name: 'SchoolEdit', params:{id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                        <router-link :to="{name: 'Event', params: {id: 1}}" class="btn btn-outline-primary">
                            <i class="bi bi-calendar-event"></i> 行事マスタ
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">東部中</td>
                    <td class="text-center">奥村</td>
                    <td class="text-center">大和郡山市</td>
                    <td class="text-center">
                        <router-link :to="{name: 'SchoolEdit', params:{id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                        <router-link :to="{name: 'Event', params: {id: 1}}" class="btn btn-outline-primary">
                            <i class="bi bi-calendar-event"></i> 行事マスタ
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">定期演奏会</td>
                    <td class="text-center">江口</td>
                    <td class="text-center"></td>
                    <td class="text-center">
                        <router-link :to="{name: 'SchoolEdit', params:{id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                        <router-link :to="{name: 'Event', params: {id: 1}}" class="btn btn-outline-primary">
                            <i class="bi bi-calendar-event"></i> 行事マスタ
                        </router-link>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">単発</td>
                    <td class="text-center">江口</td>
                    <td class="text-center"></td>
                    <td class="text-center">
                        <router-link :to="{name: 'SchoolEdit', params:{id: 1}}" class="btn btn-primary me-4">
                            <i class="bi bi-pencil-square"></i> 編集
                        </router-link>
                        <router-link :to="{name: 'Event', params: {id: 1}}" class="btn btn-outline-primary">
                            <i class="bi bi-calendar-event"></i> 行事マスタ
                        </router-link>
                    </td>
                </tr>
            </tbody>
            <tfoot></tfoot>
        </table>
        <div class="d-flex justify-content-center">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                    </li>
                </ul>
            </nav>
        </div>

    </section>

</template>

<script>
import PageTitle from '@/components/PageTitle';


export default {
    name: 'SchoolList',
    data() {
        return {

        }
    },
    components: {
        PageTitle,
    }
}
</script>

<style scoped>

</style>
